$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: var(--b-shadow-soft);
  border: #{toRem(1)} solid var(--b-color-icon-dark);
  border-radius: #{toRem(16)};
  min-width: #{toRem(32)};
  height: #{toRem(32)};

  background-color: unset;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  box-shadow: unset;
  border: #{toRem(1)} solid var(--b-color-fill-neutral-02);
}

.leftIcon {
  left: 0;
}

.rightIcon {
  right: 0;
}
