$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';

.guaranteePolicyItem {
  position: relative;

  color: var(--b-color-text-dark);

  @include font-m-300;

  [dir='rtl'] & {
    padding-left: unset;
    padding-right: var(--b-space-m);
  }
}
