$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.week {
  :global {
    // Contenedor de nombres la semana + días
    .rmdp-day-picker {
      margin: var(--b-space-m) 0;
      height: #{toRem(320)};
      padding: unset;

      // Dentro del div de clase rmdp-day-picker hay dos div sin clase, uno por cada mes
      & > div {
        width: 100%;

        :last-child {
          margin-bottom: unset;
        }
      }
    }

    // Semana (también incluye la línea de los nombres de los días)
    .rmdp-week {
      margin-bottom: var(--b-space-m);
    }

    // Nombre de los días de la semana
    .rmdp-week-day {
      @include font-xs-700;

      flex-grow: 1;

      min-width: #{toRem(40)};
      height: #{toRem(32)};

      color: var(--b-color-text-mid);

      &:first-child,
      :last-child {
        min-width: #{toRem(36)};
      }
    }
  }
}
