$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mixins/common';

.container {
  position: relative;

  :global {
    .rmdp-border {
      border: unset;
    }
  }
}

.loadingWrapper {
  position: absolute;
  z-index: var(--b-z-index-1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255,255,255,0.8);
}

.calendar {
  //necesario para que react-multi-date-picker posicione correctamente los meses
  [dir='rtl'] & {
    direction: rtl;
  }

  // Pseudo-clase que se tiene que poner para acceder a las clases de la librería
  :global {
    // Clase que establece un borde en la parte inferior del componente sin incluir el plugin que se le añada (footer)
    .rmdp-border-bottom {
      border: unset;
    }

    // Calendario completo
    .rmdp-calendar {
      padding: unset;
    }

    // Cabecera (navIcons + mes + año)
    .rmdp-header {
      margin: unset;
      padding: unset;

      // Dentro de rmdp-header hay un div que envuelve los botones y los rmdp-header-values
      & > :first-child {
        // Acceder al segundo hijo (rmdp-header-values de la izquierda)
        & > :nth-child(2) {
          padding-right: var(--b-space-xl);

          [dir='rtl'] & {
            padding-left: var(--b-space-xl);
            padding-right: unset;
          }
        }

        // Acceder al tercer hijo (rmdp-header-values de la derecha)
        & > :nth-child(3) {
          padding-left: var(--b-space-xl);

          [dir='rtl'] & {
            padding-left: unset;
            padding-right: var(--b-space-xl);
          }
        }
      }
    }

    // Nombre del mes + año
    .rmdp-header-values {
      @include font-m-300;

      margin: unset;
      width: 100%;

      // Dentro del div de clase rmdp-header-values hay dos span (el primero mes y el segundo el año)
      & > span {
        padding: unset;

        &:first-child {
          @include font-m-700;
        }
      }
    }
  }
}
