$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.dotsContainer {
  display: flex;
  gap: var(--b-space-s);

  &:last-child {
    margin-bottom: var(--b-space-xs);
  }
}
