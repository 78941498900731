$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/fonts';

.name {
  @include font-xs-300;

  text-align: center;
  overflow-wrap: break-word;
  white-space: nowrap;

  // se usa el before para evitar un desplazamiento en mobile que se produce por el cambio de grosor del texto.
  &:before {
    @include font-xs-700;
    content: attr(data-content);

    display: block;
    overflow: hidden;

    height: 0;

    visibility: hidden;
  }

  @media #{$fromTablet} {
    max-width: #{toRem(150)};
  }

  @media #{$fromLaptop} {
    @include font-m-300;

    text-align: left;
    white-space: unset;

    [dir='rtl'] & {
      text-align: right;
    }
  }
}

.visited {
  @include font-xs-500;

  @media #{$fromLaptop} {
    @include font-m-500;
  }
}

.current {
  @include font-xs-700;

  @media #{$fromLaptop} {
    @include font-m-700;
  }
}
