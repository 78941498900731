$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  border: #{toRem(1)} solid var(--b-color-fill-neutral-04);
  border-radius: #{toRem(8)};
  width: #{toRem(32)};
  height: #{toRem(32)};

  color: var(--b-color-icon-dark);

  &:disabled {
    border: #{toRem(1)} solid var(--b-color-interactive-primary-disabled);

    color: var(--b-color-interactive-primary-disabled);
    -webkit-text-fill-color: var(--b-color-text-disabled);
  }

  &:not(:disabled):hover {
    border: none;

    cursor: pointer;
    background-color: var(--b-color-interactive-primary-hover);
  }

  &:not(:disabled):active {
    background-color: var(--b-color-interactive-primary-pressed);
  }
}

.counterNumber {
  width: #{toRem(56)};

  text-align: center;
}
