$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.backWrapper {
  display: block;
}

.back {
  display: flex;
  align-items: center;

  padding: var(--b-space-s) 0;
}
