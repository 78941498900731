$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.container {
  position: relative;

  :global {
    .rmdp-border {
      border: unset;
    }
    .rmdp-wrapper {
      width: 100%;
    }
  }
}

.loadingWrapper {
  position: absolute;
  z-index: var(--b-z-index-1);
  top: 0;
  right: 0;
  bottom: toRem(80);
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 100);
}

.calendar {
  //necesario para que react-multi-date-picker posicione correctamente los meses
  [dir='rtl'] & {
    direction: rtl;
  }

  // Pseudo-clase que se tiene que poner para acceder a las clases de la librería
  :global {
    // Clase que establece un borde en la parte inferior del componente sin incluir el plugin que se le añada (footer)
    .rmdp-border-bottom {
      border: unset;
    }

    // Calendario completo
    .rmdp-calendar {
      width: 100%;
      padding: unset;
    }

    // Contenedor de nombres la semana + días
    .rmdp-day-picker {
      justify-content: space-between;

      height: 100%;
      padding: unset;

      // Dentro del div de clase rmdp-day-picker hay dos div sin clase, uno por cada mes
      & > div {
        margin-right: unset !important;

        // Primer mes (el de la izquierda)
        &:first-child {
          margin-left: var(--b-space-xl) !important;
        }

        // Segundo mes (el de la derecha)
        &:last-child {
          margin-right: var(--b-space-xl) !important;
        }
      }
    }

    // Cabecera (navIcons + mes + año)
    .rmdp-header {
      margin: unset;
      margin-bottom: var(--b-space-xs);
      padding: unset;

      // Dentro de rmdp-header hay un div que envuelve los botones y los rmdp-header-values
      & > :first-child {
        // Acceder al segundo hijo (rmdp-header-values de la izquierda)
        & > :nth-child(2) {
          margin-right: auto;

          [dir='rtl'] & {
            margin-left: auto;
            margin-right: unset;
          }
        }

        // Acceder al tercer hijo (rmdp-header-values de la derecha)
        & > :nth-child(3) {
          margin-left: auto;

          [dir='rtl'] & {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }

    // Nombre del mes + año
    .rmdp-header-values {
      @include font-m-300;

      margin: unset;
      width: toRem(326);

      // Dentro del div de clase rmdp-header-values hay dos span (el primero mes y el segundo el año)
      & > span {
        padding: unset;

        &:first-child {
          @include font-m-700;
        }
      }
    }
  }
}
