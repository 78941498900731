$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.disabledDate {
  position: relative;

  text-decoration: line-through;
  text-decoration-thickness: #{toRem(1.5)};

  color: var(--b-color-text-disabled);
  -webkit-text-fill-color: var(--b-color-text-disabled);
  cursor: pointer;
}

.enabledDate {
  cursor: pointer;
}
