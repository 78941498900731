$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.divider {
  margin-top: #{toRem(12)};
  border-radius: #{toRem(1)};
  width: 100%;
  height: #{toRem(2)};

  @media #{$onlyDesktop} {
    margin-top: #{toRem(16)};
  }
}
