$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import '../CalendarModal.module';

.week {
  :global {
    // Semana (también incluye la línea de los nombres de los días)
    .rmdp-week {
      margin-bottom: var(--b-space-xxs);
      width: 100%;

      @media #{$fromLaptop} {
        min-width: toRem(358);
      }

      &:last-child {
        margin-bottom: unset;
      }
    }

    // Nombre de los días de la semana
    .rmdp-week-day {
      @include font-s-700;

      flex-grow: 1;

      width: $dayWidth;
      height: $dayHeight;

      color: var(--b-color-text-mid);
    }
  }
}
