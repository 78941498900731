$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mixins/common';
@import 'src/ui/styles/tools/mediaQueries';

.container {
  position: relative;

  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

  width: 100%;
  height: 100%;

  // Reserva el espacio del calendario hasta que se carga para evitar
  // un efecto de flickering
  @media #{$fromTablet} {
    min-height: toRem(547);
  }
  @media #{$fromLaptop} {
    min-height: toRem(448);
  }
}

.buttonWrapper {
  @include fullWidthContainer;
  @include fullWidthContainerLateralPadding;
  @include stickyButtonsWrapper;

  @media #{$fromTablet} {
    position: relative;

    margin: unset;
    margin-top: var(--b-space-l);
    padding: unset;
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100%;

  @media #{$fromLaptop} {
    grid-template-rows: 1fr;

    width: unset;
  }
}

.alertReturnStep1 {
  margin-bottom: var(--b-space-xl);

  @media #{$fromLaptop} {
    margin-top: var(--b-space-m);
    margin-bottom: var(--b-space-2xl);
  }
}

