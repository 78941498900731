$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: var(--b-shadow-soft);
  border: var(--b-border-radius-1) solid var(--b-color-icon-dark);
  border-radius: var(--b-border-radius-16);
  min-width: toRem(32);
  height: toRem(32);

  background-color: unset;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  box-shadow: unset;
  border: var(--b-border-radius-1) solid var(--b-color-fill-neutral-02);
}

.leftIcon {
  left: 0;
}

.rightIcon {
  right: 0;
}
