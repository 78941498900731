$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.container {
  display: flex;
  gap: var(--b-space-xs);
  align-items: center;
}

.bullet {
  flex-shrink: 0;

  border-radius: #{toRem(2)};
  width: #{toRem(4)};
  height: #{toRem(20)};
}

.color-primary {
  background-color: var(--b-color-fill-ocean-15);
}

.color-dark {
  background-color: var(--b-color-text-dark);
}

.color-support-success {
  background-color: var(--b-color-support-success);
}
