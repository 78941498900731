$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

$dayWidth: toRem(40);
$dayHeight: toRem(40);

.container {
  position: relative;

  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;
  justify-items: center;

  height: 100%;
  padding: var(--b-space-m) var(--b-space-m) 0 var(--b-space-m);

  overflow-y: auto;
  overflow-x: hidden;

  @media #{$fromTablet} {
    padding-top: var(--b-space-l);
  }

  @media #{$fromLaptop} {
    overflow: hidden;

    width: 100%;
    min-height: toRem(448);
    padding: unset;
  }
}

.form {
  height: 100%;
}
