$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';
@import '../../CalendarModal.module';

.disabledDate {
  position: relative;

  text-decoration: line-through;
  text-decoration-thickness: toRem(1.5);

  color: var(--b-color-text-disabled);
  -webkit-text-fill-color: var(--b-color-text-disabled);
  cursor: pointer;

  // Quita el color del dot del rango de precios cuando el día está disabled.
  & + div > div {
    background-color: unset !important;
  }
}

.disabledToday {
  border: toRem(1) solid var(--b-color-interactive-primary-disabled);
  border-radius: toRem(3);

  background-color: unset;
}

.enabledDate {
  cursor: pointer;
}

.arrowOutIcon {
  position: absolute;
  top: toRem(4);
  right: toRem(4);
}

.arrowInIcon {
  position: absolute;
  top: toRem(4);
  left: toRem(4);
}

.dayButton {
  align-self: center;

  width: $dayWidth;
  height: $dayHeight;
}
