$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.notificationWrapper {
  position: absolute;
  z-index: var(--b-z-index-5);

  width: 100%;

  animation: slideInBottom 0.5s ease-in-out;
}

@keyframes slideInBottom {
  from {
    transform: translate(0, 9vh);
  }
  to {
    transform: translate(0, 0);
  }
}

.notification {
  display: flex;
  gap: var(--b-space-s);
  justify-content: space-between;
  align-items: flex-start;

  border: toRem(1) solid var(--b-color-fill-neutral-03);
  border-radius: toRem(8);
  padding: var(--b-space-s);

  color: var(--b-color-text-dark);
  background-color: var(--b-color-fill-neutral-01);

  &.success {
    border-color: var(--b-color-support-success-dark);

    background-color: var(--b-color-support-success-light);
  }

  &.info {
    border-color: var(--b-color-support-info-dark);

    background-color: var(--b-color-support-info-light);
  }

  &.warning {
    border-color: var(--b-color-support-warning-dark);

    background-color: var(--b-color-support-warning-light);
  }

  &.error {
    border-color: var(--b-color-support-error-dark);

    background-color: var(--b-color-support-error-light);
  }
}

.notificationContent {
  display: flex;
  gap: var(--b-space-s);
  align-items: center;

  text-align: start;
}

.exitButton {
  cursor: pointer;
}
