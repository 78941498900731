$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';

// ## TODO: ¿Pasa a Flex?
.showDetailsWrapper {
  display: grid;
  grid-template-columns: 6rem 6fr;
  @media #{$fromLaptop} {
    margin-bottom: var(--b-space-2xl);
  }
}

.detailsRow {
  grid-column: 1/3;
}

.divider {
  grid-column: 1/3;
}
