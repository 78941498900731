$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.errorMessage {
  text-align: start;
}

.divider {
  width: #{toRem(0.5)};
  height: #{toRem(24)};

  background-color: var(--b-color-fill-neutral-03);
}
