$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/gridLayout';

.title {
  position: sticky;
  z-index: var(--b-z-index-2);
  top: 0;

  margin-left: calc(var(--b-space-l) * -1);
  margin-right: calc(var(--b-space-l) * -1);
  padding-bottom: var(--b-space-m);

  background-color: var(--b-color-bg-white);
  text-align: center;

  @media #{$fromTablet} {
    margin-left: calc(var(--b-space-3xl) * -1);
    margin-right: calc(var(--b-space-3xl) * -1);
  }

  @media #{$fromLaptop} {
    margin-left: unset;
    margin-right: unset;

    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }
  }
}

.contentContainer {
  @include gridLayout;
}

.firstColumn {
  grid-column: 1 / -1;

  @media #{$fromLaptop} {
    grid-column: 1 / span 8;
  }
}

.secondColumn {
  grid-column: 1 / -1;

  @media #{$fromLaptop} {
    grid-column: 9 / span 4;
  }
}

.carouselContainer {
  position: relative;

  margin-bottom: var(--b-space-xl);
  margin-left: calc(var(--b-space-l) * -1);
  margin-right: calc(var(--b-space-l) * -1);

  @media #{$fromTablet} {
    margin-left: calc(var(--b-space-3xl) * -1);
    margin-right: calc(var(--b-space-3xl) * -1);
  }

  @media #{$fromLaptop} {
    overflow: hidden;

    margin-left: unset;
    margin-right: unset;
  }
}

.divider {
  margin-bottom: var(--b-space-l);

  @media #{$fromLaptop} {
    &:first-child {
      display: none;
    }
    margin-bottom: var(--b-space-m);
  }
}

.roomInfo {
  display: flex;
  justify-content: space-between;

  margin-bottom: var(--b-space-l);

  @media #{$fromLaptop} {
    gap: var(--b-space-m);
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: unset;
  }
}

.serviceImage {
  flex-shrink: 0;

  // hack: busca eliminar el color "azul" de los iconos de forma artifical
  filter: grayscale(100%) brightness(115%);
}
