$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.day {
  :global {
    // Día de la semana
    .rmdp-day {
      @include font-s-700;

      width: #{toRem(48)};
      height: #{toRem(32)};

      &.rmdp-range > .sd > * {
        color: var(--b-color-text-light);
      }
    }

    // sd = single day -> span que hay dentro de cada día de la semana
    .sd {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      margin: auto;
      width: #{toRem(32)};
      height: #{toRem(32)};
      padding: var(--b-space-xs);
    }

    // Rango de selección
    .rmdp-range {
      box-shadow: unset;

      background-color: unset;

      & > * {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-04) !important;
        & > button > span {
          color: var(--b-color-white);
        }
      }

      :hover {
        background-color: var(--b-color-fill-neutral-04) !important;
        color: var(--b-color-white);
      }
    }

    // Rango de selección cuando aún no hay checkOut (el usuario está haciendo hover por los días)
    .rmdp-range-hover {
      color: var(--b-color-text-dark);
      background-color: var(--b-color-bg-light);
    }

    // Dentro del rango de selección, el primer elemento y el último
    .rmdp-range-hover.start,
    .rmdp-range-hover.end {
      background-color: unset;
    }

    // Todos los span de día sobre los que se haga hover excepto si el día no se muestra (está el hueco en blanco), es un rango o está deshabilitado.
    .rmdp-day:not(.rmdp-day-hidden, .rmdp-range, .rmdp-disabled) span:hover {
      border-radius: #{toRem(8)};

      color: var(--b-color-text-dark) !important;
      background-color: var(--b-color-fill-neutral-02) !important;
    }

    // Si el día está oculto (hay un hueco en el mes), deshabilita cualquier evento porque se hacía hover los días del siguiente mes
    .rmdp-day.rmdp-day-hidden {
      pointer-events: none;
    }
  }
}

.selectedDays {
  :global {
    .rmdp-day.rmdp-range > .sd > * {
      color: var(--b-color-text-dark);
    }

    .rmdp-range {
      color: var(--b-color-text-dark);
      background-color: var(--b-color-bg-light);

      & > * {
        background-color: unset !important;
      }

      :hover {
        color: var(--b-color-text-dark) !important;
        background-color: unset !important;
      }
    }

    .rmdp-range.start {
      color: var(--b-color-text-light);
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-bg-light) 49%,
        var(--b-color-bg-light) 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 49%,
          transparent 49%,
          transparent 100%
        );
      }

      & > * {
        background-color: var(--b-color-fill-neutral-04) !important;
      }

      & .sd > *, :hover {
        color: var(--b-color-text-light) !important;
      }
    }

    .rmdp-range.end {
      color: var(--b-color-text-light);
      background: linear-gradient(
        90deg,
        var(--b-color-bg-light) 0%,
        var(--b-color-bg-light) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-bg-light) 49%,
          var(--b-color-bg-light) 100%
        );
      }

      & > * {
        background-color: var(--b-color-fill-neutral-04) !important;
      }

      & .sd > *, :hover {
        color: var(--b-color-text-light) !important;
      }
    }
  }
}

.previousDay {
  :global {
    .rmdp-range {
      background: linear-gradient(
        90deg,
        var(--b-color-bg-light) 0%,
        var(--b-color-bg-light) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-bg-light) 49%,
          var(--b-color-bg-light) 100%
        );
      }

      &:has(+ .rmdp-range-hover) {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-bg-light) 49%,
          var(--b-color-bg-light) 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            var(--b-color-bg-light) 0%,
            var(--b-color-bg-light) 49%,
            transparent 49%,
            transparent 100%
          );
        }
      }
    }

    // Si un día no tiene la clase .rmdp-range-hover y tiene seguido un hermano con la clase .rmdp-range
    // y otro hermano seguido sin la clase .rmdp-range-hover,
    // entonces quita el background del hermano que tiene la clase .rmdp-range
    .rmdp-day:not(.rmdp-range-hover):has(
        + .rmdp-range + .rmdp-day:not(.rmdp-range-hover)
      ) {
      & + .rmdp-range {
        background: unset;
      }
    }

    .rmdp-range-hover.start {
      .sd {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-02);
      }

      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-bg-light) 49%,

        var(--b-color-bg-light) 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 49%,
          transparent 49%,
          transparent 100%
        );
      }
    }

    .rmdp-range-hover.end {
      .sd {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-02);
      }

      background: linear-gradient(
        90deg,
        var(--b-color-bg-light) 0%,

        var(--b-color-bg-light) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-bg-light) 49%,
          var(--b-color-bg-light) 100%
        );
      }
    }
  }
}

.nextDay {
  :global {
    .rmdp-day.rmdp-range {
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-bg-light) 49%,
        var(--b-color-bg-light) 100%
      );

      [dir='rtl'] & {
        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 49%,
          transparent 49%,
          transparent 100%
        );
      }

      &:has(+ .rmdp-day:not(.rmdp-range-hover)) {
        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 49%,
          transparent 49%,
          transparent 100%
        );

        [dir='rtl'] & {
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 49%,
            var(--b-color-bg-light) 49%,
            var(--b-color-bg-light) 100%
          );
        }
      }
    }

    // Si un día no tiene la clase .rmdp-range-hover y tiene seguido un hermano con la clase .rmdp-range
    // y otro hermano seguido sin la clase .rmdp-range-hover,
    // entonces quita el background del hermano que tiene la clase .rmdp-range
    .rmdp-day:not(.rmdp-range-hover):has(
        + .rmdp-range + .rmdp-day:not(.rmdp-range-hover)
      ) {
      & + .rmdp-range {
        background: unset;
      }
    }

    .rmdp-day.rmdp-range-hover.start {
      .sd {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-02);
      }

      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-bg-light) 49%,
        var(--b-color-bg-light) 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,

          var(--b-color-bg-light) 49%,
          transparent 49%,
          transparent 100%
        );
      }
    }

    .rmdp-day.rmdp-range-hover.end {
      .sd {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-02);
      }

      background: linear-gradient(
        90deg,
        var(--b-color-bg-light) 0%,
        var(--b-color-bg-light) 49%,
        transparent 49%,
        transparent 100%
      );

      [dir='rtl'] & {
        border-radius: unset;

        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 49%,
          var(--b-color-bg-light) 49%,
          var(--b-color-bg-light) 100%
        );
      }
    }
  }
}
