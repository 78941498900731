$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.container {
  z-index: unset;

  :global {
    .rmdp-wrapper {
      width: unset;
    }
    .rmdp-border {
      border: unset;
    }
  }
}

.calendar {
  //necesario para que react-multi-date-picker posicione correctamente los meses
  [dir='rtl'] & {
    direction: rtl;
  }

  // Pseudo-clase que se tiene que poner para acceder a las clases de la librería
  :global {
    // Calendario completo
    .rmdp-calendar {
      width: 100%;
      padding: unset;
    }

    // Cabecera (navIcons + mes + año)
    .rmdp-header {
      margin: unset;
      padding: unset;
    }

    // Nombre del mes + año
    .rmdp-header-values {
      @include font-m-300;

      margin: unset;
      width: 100%;

      // Dentro del div de clase rmdp-header-values hay dos span (el primero mes y el segundo el año)
      & > span {
        padding: unset;

        &:first-child {
          @include font-m-700;
        }
      }
    }
  }
}
