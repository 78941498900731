$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.day {
  :global {
    // Día de la semana
    .rmdp-day {
      @include font-s-700;

      flex-grow: 1;

      min-width: #{toRem(40)};
      height: #{toRem(32)};

      &.rmdp-range > .sd > * {
        color: var(--b-color-text-light);
      }
    }

    // sd = single day -> span que hay dentro de cada día de la semana
    .sd {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      margin: auto;
      width: #{toRem(32)};
      height: #{toRem(32)};
      padding: var(--b-space-xs);
    }

    // Rango de selección
    .rmdp-range {
      box-shadow: unset;

      background-color: unset;

      & > * {
        border-radius: #{toRem(8)};

        background-color: var(--b-color-fill-neutral-04) !important;
      }

      :hover {
        background-color: var(--b-color-fill-neutral-04) !important;
      }
    }

    // Rango de selección cuando aún no hay checkOut (el usuario está haciendo hover por los días)
    .rmdp-range-hover {
      color: var(--b-color-text-dark);
      background-color: unset;
    }

    // Todos los span de día sobre los que se haga hover excepto si el día no se muestra (está el hueco en blanco), es un rango o está deshabilitado.
    .rmdp-day:not(.rmdp-day-hidden, .rmdp-range, .rmdp-disabled) span:hover {
      border-radius: #{toRem(8)};

      color: var(--b-color-text-dark) !important;
      background-color: var(--b-color-fill-neutral-02) !important;
    }

    // Cuando se llegaba a estas medidas, la librería cambiaba el tamaño del texto
    @media (max-height: 450px), (max-width: 450px) {
      .rmdp-day span {
        padding-left: unset;

        font-size: unset;
      }
    }
  }
}

.disableSelectSameDayTwice {
  :global {
    .rmdp-range {
      cursor: default;
      pointer-events: none;
    }
  }
}

.selectedDays {
  :global {
    .rmdp-day.rmdp-range > .sd > * {
      color: var(--b-color-text-dark);
    }

    .rmdp-range {
      color: var(--b-color-text-dark);
      background-color: var(--b-color-bg-light);

      & > * {
        background-color: unset !important;
      }

      :hover {
        color: var(--b-color-text-dark) !important;
        background-color: unset !important;
      }
    }

    .rmdp-range.start {
      color: var(--b-color-text-light);
      background: linear-gradient(
        90deg,
        transparent 0%,
        transparent 49%,
        var(--b-color-bg-light) 49%,
        var(--b-color-bg-light) 100%
      ) !important;

      // -webkit-linear-gradient va de left to right y el linear-gradient va de bottom to top y la dirección
      // en linear-gradient va en clockwise y el -webkit-linear-gradient va en anti-clockwise
      background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 49%,
        var(--b-color-bg-light) 49%,
        var(--b-color-bg-light) 100%
      ) !important;

      & > * {
        background-color: var(--b-color-fill-neutral-04) !important;
      }

      & .sd > *, :hover {
        color: var(--b-color-text-light) !important;
      }
    }

    .rmdp-range.end {
      color: var(--b-color-text-light);
      background: linear-gradient(
        90deg,
        var(--b-color-bg-light) 0%,
        var(--b-color-bg-light) 49%,
        transparent 49%,
        transparent 100%
      ) !important;

      // -webkit-linear-gradient va de left to right y el linear-gradient va de bottom to top y la dirección
      // en linear-gradient va en clockwise y el -webkit-linear-gradient va en anti-clockwise
      background: -webkit-linear-gradient(
        0deg,
        var(--b-color-bg-light) 0%,
        var(--b-color-bg-light) 49%,
        rgba(255, 255, 255, 0) 49%,
        rgba(255, 255, 255, 0) 100%
      ) !important;

      & > * {
        background-color: var(--b-color-fill-neutral-04) !important;
      }

      & .sd > *, :hover {
        color: var(--b-color-text-light) !important;
      }
    }

    .rmdp-week {
      & > .rmdp-range:first-child {
        background: linear-gradient(
          90deg,
          transparent 0%,
          transparent 15%,
          var(--b-color-bg-light) 15%,
          var(--b-color-bg-light) 100%
        );

        // -webkit-linear-gradient va de left to right y el linear-gradient va de bottom to top y la dirección
        // en linear-gradient va en clockwise y el -webkit-linear-gradient va en anti-clockwise
        background: -webkit-linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 15%,
          var(--b-color-bg-light) 15%,
          var(--b-color-bg-light) 100%
        );
      }

      & > .rmdp-range:last-child {
        background: linear-gradient(
          90deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 85%,
          transparent 85%,
          transparent 100%
        );

        // -webkit-linear-gradient va de left to right y el linear-gradient va de bottom to top y la dirección
        // en linear-gradient va en clockwise y el -webkit-linear-gradient va en anti-clockwise
        background: -webkit-linear-gradient(
          0deg,
          var(--b-color-bg-light) 0%,
          var(--b-color-bg-light) 85%,
          rgba(255, 255, 255, 0) 85%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      & > .rmdp-range.start:last-child {
        background: unset !important;
      }

      & > .rmdp-range.end:first-child {
        background: unset !important;
      }
    }
  }
}
