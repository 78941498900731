$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mediaQueries';

.wrapper {
  display: flex;
  gap: var(--b-space-xs);
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;

  border: #{toRem(2)} solid var(--b-color-interactive-primary-enabled);
  border-radius: 50%;
  width: #{toRem(24)};
  height: #{toRem(24)};

  @media #{$onlyDesktop} {
    width: #{toRem(32)};
    height: #{toRem(32)};

    .wrapper:hover & {
      background-color: var(--b-color-interactive-primary-hover);
      color: var(--b-color-text-dark);
    }
  }

  &.current {
    border: #{toRem(2)} solid var(--b-color-interactive-primary-active);

    background-color: var(--b-color-interactive-primary-active);
    color: var(--b-color-text-light);
  }

  &.current.disabled {
    border: #{toRem(2)} solid var(--b-color-interactive-primary-active);
  }

  &.disabled {
    border: #{toRem(2)} solid var(--b-color-interactive-primary-disabled);
  }

  &.visited {
    border: #{toRem(2)} solid var(--b-color-support-success-light);

    background-color: var(--b-color-stepper-enabled);
    color: var(--b-color-text-light);

    @media #{$onlyDesktop} {
      .wrapper:hover & {
        border: #{toRem(2)} solid var(--b-color-support-success-light);

        background-color: var(--b-color-stepper-hover);
        color: var(--b-color-text-light);
      }
    }
  }
}
