$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.week {
  :global {
    // Contenedor de nombres la semana + días
    .rmdp-day-picker {
      gap: var(--b-space-4xl);
      justify-content: center;

      margin: var(--b-space-m) var(--b-space-l) var(--b-space-xl)
        var(--b-space-l);
      height: #{toRem(320)};
      padding: unset;

      // Dentro del div de clase rmdp-day-picker hay dos div sin clase, uno por cada mes
      & > div {
        margin-right: unset !important;

        & > :last-child {
          margin-bottom: unset;
        }
      }
    }

    // Semana (también incluye la línea de los nombres de los días)
    .rmdp-week {
      margin-bottom: var(--b-space-m);

      & > :first-child {
        width: #{toRem(40)};

        & > span {
          margin: unset;
        }
      }

      & > :last-child {
        width: #{toRem(40)};

        & > span {
          margin: unset;
          margin-left: var(--b-space-xs);

          [dir='rtl'] & {
            margin-left: unset;
            margin-right: var(--b-space-xs);
          }
        }
      }
    }

    // Nombre de los días de la semana
    .rmdp-week-day {
      @include font-xs-700;

      width: #{toRem(48)};
      height: #{toRem(32)};

      color: var(--b-color-text-mid);

      &:first-child,
      :last-child {
        width: #{toRem(40)};
      }
    }
  }
}
