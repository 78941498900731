$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.logo {
  display: inline-block;
  svg {
    width: toRem(100);
    height: toRem(100);

    g#b-fill path {
      fill: transparent;
      animation: b-fill 2s ease-in-out infinite alternate;
    }
    g#b-hole-1 path {
      stroke-dasharray: 350;
      stroke-dashoffset: 350;
      stroke-width: toRem(4);
      animation: b-holes 2s linear infinite alternate;
    }
    g#b-hole-2 path {
      stroke-dasharray: 350;
      stroke-dashoffset: 350;
      stroke-width: toRem(4);
      animation: b-holes 2s linear infinite alternate;
    }
    g#b-stroke path {
      stroke-dasharray: 790;
      stroke-dashoffset: 790;
      stroke-width: toRem(4);
      animation: b-stroke 2s linear infinite alternate;
    }
    g#circle path {
      stroke-dasharray: 720;
      stroke-dashoffset: 720;
      stroke-width: toRem(2);
      animation: circle 2s linear infinite alternate;
    }
    g#line-top line {
      stroke-dasharray: 260;
      stroke-dashoffset: 260;
      stroke-width: toRem(2);
      animation: lines-tb 2s linear infinite alternate;
    }
    g#line-bottom line {
      stroke-dasharray: 260;
      stroke-dashoffset: 260;
      stroke-width: toRem(2);
      animation: lines-tb 2s linear infinite alternate;
    }
    g#line-section-right line {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      stroke-width: toRem(2);
      animation: line-section-right 4s linear infinite;
    }
    g#line-section-left line {
      stroke-dasharray: 100;
      stroke-dashoffset: 100;
      stroke-width: toRem(2);
      animation: line-section-left 4s linear infinite;
    }
  }

  .st0 {
    fill: #384044;
  }

  .st1 {
    fill: none;
    stroke: #384044;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill: none;
    stroke: #384044;
    stroke-width: 1.2853;
    stroke-miterlimit: 10;
  }
  .st3 {
    fill: none;
    stroke: #384044;
    stroke-width: 1.285;
    stroke-miterlimit: 10;
  }
}

@keyframes b-fill {
  0% {
    fill: transparent;
  }
  60% {
    fill: transparent;
  }
  80% {
    fill: #384044;
  }
  100% {
    fill: #384044;
  }
}
@keyframes b-holes {
  0% {
    stroke-dashoffset: 350;
  }
  10% {
    opacity: 0;

    stroke-dashoffset: 350;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;

    stroke-dashoffset: 0;
  }
}

@keyframes b-stroke {
  0% {
    opacity: 0;

    stroke-dashoffset: 800;
  }
  20% {
    opacity: 1;
  }
  60% {
    opacity: 1;

    stroke-dashoffset: 0;
  }
  100% {
    opacity: 1;

    stroke-dashoffset: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 0;

    stroke-dashoffset: 720;
  }
  20% {
    opacity: 1;
  }
  40% {
    stroke-dashoffset: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0;

    stroke-dashoffset: -720;
  }
  100% {
    stroke-dashoffset: -720;
  }
}

@keyframes lines-tb {
  0% {
    opacity: 0;

    stroke-dashoffset: 260;
  }
  10% {
    opacity: 1;
  }
  40% {
    stroke-dashoffset: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;

    stroke-dashoffset: -260;
  }
  100% {
    stroke-dashoffset: -260;
  }
}

@keyframes line-section-right {
  0% {
    opacity: 0;

    stroke-dashoffset: 100;
  }
  10% {
    opacity: 1;
  }
  20% {
    stroke-dashoffset: 0;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;

    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: -100;
  }
}

@keyframes line-section-left {
  0% {
    stroke-dashoffset: 100;
  }
  60% {
    opacity: 0;

    stroke-dashoffset: 100;
  }
  70% {
    opacity: 1;
  }
  80% {
    stroke-dashoffset: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;

    stroke-dashoffset: -100;
  }
}
