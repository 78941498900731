$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.container {
  display: flex;
  flex-direction: column;

  padding: var(--b-space-l) 0;
}

.firstRoom {
  padding-top: unset;
}

.lastRoom {
  padding-bottom: unset;
}

.collapsedContainer {
  gap: unset;

  &:hover {
    cursor: pointer;
  }
}

.guestItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.info {
  display: flex;
  gap: var(--b-space-xs);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
